import React from "react";
import Layout from "./src/components/Layout";

/**
 * Gatsby's Browser APIs.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};