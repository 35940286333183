import React, { useState } from "react";
import { Link } from "gatsby";
import "../styles/header.css"; // Import styles for the header

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="site-header">
      <div className="container">
        {/* Top row: Title and social icons */}
        <div className="header-top">
          <h1 className="site-title">
            <Link to="/">phillipmorrow.com</Link>
          </h1>
          <div className="social-icons">
            <a
              href="https://github.com/phillip-morrow"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/icons/github.png" className="iconSize" alt="GitHub" />
            </a>
            <a
              href="https://www.linkedin.com/in/phillip-m-2552ba5b/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/icons/linkedin.png" className="iconSize" alt="LinkedIn" />
            </a>
            <a
              href="https://www.codewars.com/users/pmorrowtron"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/icons/codewars.svg" className="iconSize" alt="Codewars" />
            </a>
          </div>
          <button
            className="hamburger-menu md:hidden"
            onClick={toggleMenu}
            aria-label="Toggle navigation menu"
          >
            ☰
          </button>
        </div>

        {/* Navigation menu */}
        <nav
          className={`nav-menu ${
            isMenuOpen ? "block" : "hidden"
          } md:block`}
        >
          <ul className="nav-list">
            <li>
              <Link to="/" className="nav-link">
                Home
              </Link>
            </li>
            <li>
              <Link to="/?category=photos" className="nav-link">
                Photo
              </Link>
            </li>
            <li>
              <Link to="/?category=video" className="nav-link">
                Video
              </Link>
            </li>
            <li>
              <Link to="/?category=code" className="nav-link">
                Code
              </Link>
            </li>
            <li>
              <Link to="/?category=blog" className="nav-link">
                Blog
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
