/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/
 */

import React from "react";
import Header from "./Header";
import "../styles/layout.css";

const Layout = ({ children }) => {
  return (
    <div>
      <Header /> {/* Render Header only once here */}
      <main>{children}</main>
    </div>
  );
};

export default Layout;
